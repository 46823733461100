import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/loggedUserContext";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";

const PrivateRouteTransactions = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    (async function () {
      try {
        const response = await authenticate();
        const accessLevel = response?.user?.access_level;
        const isTrueSet =
          accessLevel === 1 ||
          accessLevel === 2 ||
          accessLevel === 3 ||
          accessLevel === 4 ||
          accessLevel === 5;

        switch (accessLevel) {
          case 3:
            return isTrueSet ? <Outlet /> : <Navigate to="/payments" />;
          default:
            return isTrueSet ? <Outlet /> : <Navigate to="/" />;
        }
      } catch (err) {
        console.log(err);
      }
    })();
  } else {
    const accessLevel = user?.access_level;
    const isTrueSet =
      accessLevel === 1 ||
      accessLevel === 2 ||
      accessLevel === 3 ||
      accessLevel === 4 ||
      accessLevel === 5;

    switch (accessLevel) {
      case 3:
        return isTrueSet ? <Outlet /> : <Navigate to="/payments" />;
      default:
        return isTrueSet ? <Outlet /> : <Navigate to="/" />;
    }
  }
};
export default PrivateRouteTransactions;
