import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/loggedUserContext";

const PrivateRouteHome = () => {
  const { user } = useContext(UserContext);

  const accessLevel = user?.access_level;
  const isTrueSet = accessLevel === 1 || 5;

  switch (accessLevel) {
    case 2:
      return isTrueSet ? <Outlet /> : <Navigate to="/transactions" />;
    case 3:
      return isTrueSet ? <Outlet /> : <Navigate to="/payments" />;
    case 4:
      return isTrueSet ? <Outlet /> : <Navigate to="/transactions" />;
    default:
      return isTrueSet ? <Outlet /> : <Navigate to="/" />;
  }
};
export default PrivateRouteHome;
